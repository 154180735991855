import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { UserInfo } from "../user-info";
import logo from "../../images/logo/logo72.png";
import { useNavigate, Link } from "react-router-dom";
import { appConfig } from "../../app-config";
import { addSearchHistory } from "src/service/redux/search-slice";
import { useSelector } from "../../tools/toolkit/store";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";
import NotificationBox from "../../components/notification/NotificationBox";
import { useGetNotificationListMutation } from "../../service/api/notification-api";
import { LastNotificationTime } from "../../models/notification";
import { showSignInUpDialog } from "src/service/redux/signinup-slice";
import SwitchLanguage from "./SwitchLanguage";
import { useTranslation } from "react-i18next";
import SwitchPostPhotoMode from "./SwitchPostPhotoMode";
import { makeStyles } from "tss-react/esm/mui";
import IcoSearch from "src/images/layout/ico-search.svg";
import IcoNotification from "src/images/layout/ico-notification.svg";
import MainTab from "./MainTab";
// import { OpenAppBoxHeight } from "../openApp/OpenAppBox";
import MobileHeader from "./MobileHeader";
import { onTableOrMobile } from "src/tools/utils/appUtil";
import SearchHistoryBox from "./SearchHistoryBox";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useGetHistoryMutation } from "src/service/api/search-api";

const IsOnTableOrMobile = onTableOrMobile();

export const PC_HEADER_HEIGHT = "72px";
export const MOBILE_HEADER_HEIGHT = "54px";
export const SEARCH_HIGHT = "100px"

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      background: "white",
      borderBottom: "1px solid #EEEEEE",
      position: "fixed",
      zIndex: 1001,
      gap: "10px",
      padding: "0 20px",
      [theme.breakpoints.down("sm")]: {
        gap: "10px",
        padding: "0",
        justifyContent: "space-between",
      },
    },
    logo: {
      width: "36px",
      height: "36px",
      [theme.breakpoints.down("sm")]: {
        width: "32px",
        height: "32px",
      },
    },
    searchBox: {
      width: "240px",
      height: "40px",
      border: "1px solid #EEEEEE",
      borderRadius: "42px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: "10px",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "36px",
        border: "1px solid #EEEEEE",
        borderRadius: "24px",
      },
    },
    searchButton: {
      height: "16px",
      width: "16px",
      whiteSpace: "nowrap",
      minWidth: "16px",
      mixBlendMode: "normal",
      borderRadius: "16px",
      padding: "0px",
      marginRight: "12px",
    },
    searchIcon: {},
  };
});

// const lastTime={
//     message:'unreadMessageNum',
//     follower:'lastFollowerTime',
//     post:'lastPostTime',
//     request:'lastRequestTime',
// }

let timer: any = null;

const Header: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [searchParams] = useSearchParams();
  // const isIframe = useMemo(() => {
  //     return !!searchParams.get('iframe');
  // }, [searchParams])
  const dispatch = useDispatch();
  // const searchValue = useSelector(state => state.search)?.searchValue as string;
  // const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [getNotificationList] = useGetNotificationListMutation();
  const { credential } = useSelector((state) => state.app);
  const [getHotList] = useGetHistoryMutation();
  const [hotList, setHotList] = useState<string[]>([]);
  const [lastNotificationTimeList, setLastNotificationTimeList] =
    useState<LastNotificationTime>();
  const [isRead, setIsRead] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchOpen, setSearchOpen] = useState<Boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>("");
  const [hasUnreadChatroomMessage, setHasUnreadChatroomMessage] = useState(false);

  const handleGetHotList = useCallback(() => {
    getHotList({})
      .unwrap()
      .then((res) => {
        if (!res?.isFailed && res?.result?.length > 0) {
          setHotList(res?.result);
          setSearchPlaceholder(res?.result[0]);
        } else {
          setHotList([]);
        }
      });
  }, [getHotList]);

  useEffect(() => {
    handleGetHotList();
  }, [handleGetHotList]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (!isSearching && hotList?.length > 0) {
      intervalId = setInterval(() => {
        setSearchPlaceholder((prevValue) => {
          const currentIndex = hotList.indexOf(prevValue);
          const nextIndex = (currentIndex + 1) % hotList.length;
          return hotList[nextIndex];
        });
      }, 10000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [hotList, isSearching]);

  const hasNewNotification = useCallback(() => {
    getNotificationList({})
      .unwrap()
      .then((res) => {
        if (res && !res.isFailed && res.result) {
          setLastNotificationTimeList(res.result.last_notification_time || {});
        } else {
          enqueueSnackbar("Failed to get notification list");
        }
      });
  }, [getNotificationList]);

  useEffect(() => {
    if (!credential) {
      // dispatch(showSignInUpDialog());
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    } else {
      hasNewNotification();
      if (!timer) {
        timer = setInterval(() => {
          hasNewNotification();
        }, 15 * 1000);
      }
    }
  }, [credential, hasNewNotification]);

  useEffect(() => {
    if (lastNotificationTimeList) {
      const {
        unread_request_num,
        unread_post_num,
        unread_user_num,
        unread_message_num,
        unread_announcement_num,
        unread_location_chat
      } = lastNotificationTimeList;

      setIsRead(
        (unread_message_num ?? 0) <= 0 &&
        (unread_user_num ?? 0) <= 0 &&
        (unread_post_num ?? 0) <= 0 &&
        (unread_request_num ?? 0) <= 0 &&
        (unread_announcement_num ?? 0) <= 0
      );
      setHasUnreadChatroomMessage(unread_location_chat > 0);
    }
  }, [lastNotificationTimeList]);

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    } else {
      setAnchorEl(event.currentTarget);
      hasNewNotification();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchValue = () => {
    handleSearch(searchValue || searchPlaceholder);
    if (!searchValue && searchPlaceholder) {
      setSearchValue(searchPlaceholder);
      setIsSearching(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchValue();
    }
  };

  const handleSearch = useCallback(
    (searchValue: string) => {
      const currentPath = window.location.pathname;
      const searchValueTrim = String(searchValue).trim();
      if (searchValueTrim === "") {
        enqueueSnackbar(t("app.header.searchEmpty"), { variant: "info" });
      }

      dispatch(addSearchHistory(searchValueTrim));

      const encodeSearchValueTrim = encodeURIComponent(searchValueTrim)

      if (currentPath !== appConfig.paths.search && currentPath.startsWith(appConfig.paths.worldview)) {
        navigate(`${appConfig.paths.worldviewSearch}/${encodeSearchValueTrim}`);
      } else if (currentPath !== appConfig.paths.search && currentPath.startsWith(appConfig.paths.travel)){
        navigate(`${appConfig.paths.search}?search_key=${encodeSearchValueTrim}&page=${appConfig.paths.travel}`);
      } else {
        navigate(`${appConfig.paths.search}?search_key=${encodeSearchValueTrim}`);
      }
      handleCloseSearch();
    },
    [dispatch, navigate, t]
  );

  const handleSearchFocus = () => {
    setSearchOpen(true);
    setIsSearching(true);
  };

  const handleSearchBlur = () => {
    setIsSearching(false);
  };

  const isMobileOrTable = useMemo(() => {
    return isMobile || IsOnTableOrMobile;
  }, [isMobile]);

  const handleCloseSearch = () => {
    setSearchOpen(false);
  };
  const handleClickAway = () => {
    handleCloseSearch();
  };

  const handleSelectHistory = useCallback((text: string) => {
    setSearchValue(text);
    handleSearch(text);
  }, [handleSearch])

  return (
    <Box
      className={classes.root}
      style={{
        height: isMobileOrTable ? MOBILE_HEADER_HEIGHT : PC_HEADER_HEIGHT,
        top: isMobileOrTable ? 'unset' : 0,
        bottom: isMobileOrTable ? 0 : 'unset',
      }}
    >
      {isMobileOrTable ? (
        <MobileHeader hasUnreadChatroomMessage={hasUnreadChatroomMessage} />
      ) : (
        <>
          <Box
            component={Link}
            to={appConfig.paths.root}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <img alt="logo" src={logo} className={classes.logo} />
            <Box
              sx={{
                fontFamily: "Helvetica Neue",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                color: "#3A92F9",
                marginLeft: "10px",
              }}
            >
              {t("app.title")}
            </Box>
          </Box>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.searchBox}>
              <TextField
                variant="standard"
                placeholder={searchPlaceholder}
                InputProps={{
                  disableUnderline: true,
                }}
                value={searchValue}
                sx={{
                  padding: "18px",
                  flexGrow: 1,
                  fontSize: "14px",
                  [theme.breakpoints.down("sm")]: { fontSize: "12px" },
                }}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
              />
              {searchOpen && (
                <SearchHistoryBox
                  handelSelectedText={handleSelectHistory}
                />
              )}
              {searchValue !== "" && (
                <Button
                  variant="text"
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                    minWidth: "0px",
                    borderRadius: "1.5rem",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    setSearchValue("");
                  }}
                >
                  <CloseIcon sx={{ fontSize: "1rem", color: "#717171" }} />
                </Button>
              )}
              <Button
                variant="text"
                onClick={handleSearchValue}
                className={classes.searchButton}
              >
                <img
                  src={IcoSearch}
                  alt="search"
                  className={classes.searchIcon}
                />
              </Button>
            </Box>
          </ClickAwayListener>
          <Box sx={{ flexGrow: 1 }} />
          <MainTab hasUnreadChatroomMessage={hasUnreadChatroomMessage} />
          <Box sx={{ flexGrow: 1 }} />
          <SwitchPostPhotoMode />
          <Tooltip title={t("app.header.notification")}>
            <IconButton
              onClick={handleClickNotification}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ width: "40px", height: "40px" }}
            >
              <Badge variant="dot" color="error" invisible={isRead} sx={{ "& .MuiBadge-badge": { backgroundColor: "rgba(239, 62, 62, 1)" } }}>
                <img
                  alt="notification"
                  src={IcoNotification}
                  style={{ width: "16px", height: "18px" }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: 360,
                minHeight: 150,
                height: 429,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 2,
                ml: isMobileOrTable ? 0 : 15,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 128,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {lastNotificationTimeList && (
              <NotificationBox
                lastNotificationTimeList1={lastNotificationTimeList}
                updateRead={(read) => {
                  setIsRead(read);
                }}
              />
            )}
          </Menu>
          <SwitchLanguage />
          <Button
            variant="outlined"
            LinkComponent={MuiLink}
            href={appConfig.paths.sendRequest}
            sx={{
              textTransform: "none",
              width: "88px",
              height: "40px",
              color: "#3A92F9",
              textAlign: "center",
              fontFamily: "Helvetica Neue",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              borderRadius: "20px",
              padding: 0,
              marginRight: "10px",
            }}
          >
            {t("app.home.request")}
          </Button>
          <UserInfo />
        </>
      )}
    </Box>
  );
};

export default Header;
