import { FC, ReactNode, memo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { isCN } from 'src/tools/utils/appUtil';

const defaultDescription = 'Discover the world authentically with AnyLocal. Our platform brings unfiltered insights into global cultures, natural wonders, and historical sites through real, unedited content. Leveraging advanced technology, we ensure the veracity of every post, validating the time and place of each shared experience.';

interface Props {
  title?: string;
  description?: string;
  children?: ReactNode;
}
const publicTitle = isCN ? '西安思科特软件有限公司' : "Time + Location Verified";

const MainMeta: FC<Props> = ({ title, description, children }) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>
        {
          isCN ? publicTitle :
            `${t("app.title")} - ${title ?? publicTitle} `
        }
      </title>
      <meta name="description" content={description ?? defaultDescription} />
      {children}
    </Helmet>
  );
}

export default memo(MainMeta);