

import { Avatar, Box, Button, Divider, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { SvgComponent } from '../../components/svgComponent';
import { useSelector } from '../../tools/toolkit/store';
import SignInUpDialog from '../../components/dialog/signIn/SignInUpDialog';
import { useDispatch } from 'react-redux';
import { closeSignInUpDialog, showSignInUpDialog } from '../../service/redux/signinup-slice';
import { clearIdentity, setIdentity } from '../../service/redux/app-slice';
import { clearRefreshToken, clearToken } from '../../service/redux/access-slice';
import ico_save from "src/images/dialog/saveList/ico-save.svg";
import ico_wallet from 'src/images/login/ico-wallet.svg'
import ico_settings from 'src/images/login/ico-settings.svg'
import ico_profile from '../../images/login/ico-profile.svg'
import ico_logout from '../../images/login/ico-logout.svg';
import ico_admin from '../../images/admin/ico-admin.svg';
import { useNavigate, Link } from 'react-router-dom';
import { appConfig } from '../../app-config';
import DefaultAvatar from '../../images/defaultIcons/default-avatar.png'
// import { deleteShopifyAccessToken } from 'src/pages/orders/common';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useGetUserProfileMutation } from "src/service/api/auth-api";
import { clearHistory } from "src/tools/utils/search-history";
import { userPagePath } from 'src/pages/user/userConfig';
import FeedbackDialog from "src/components/dialog/feedback/FeedbackDialog";
import FeedbackTipDialog from "src/components/dialog/feedback/FeedbackTipDialog"
import IcoMember from "src/images/member/ico-member.svg"

const UserInfo: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { credential } = useSelector(state => state.app);
    const { showSignInDialog } = useSelector(state => state.signinup);
    const { showFeedbackDialog, showFeedbackAlert } = useSelector((state) => state.feedback);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isShow, setIsShow] = useState<boolean>(false)
    const [getUserProfile] = useGetUserProfileMutation();

    useEffect(() => {
        document.addEventListener('click', () => { setIsShow(false) })
    }, [])

    useEffect(() => {
        if (credential) {
            dispatch(closeSignInUpDialog())
        }
        // eslint-disable-next-line
    }, [credential])

    const gethUserInfo = useCallback(() => {
        if (!credential) return;
        getUserProfile({ userId: credential.user_id })
            .unwrap()
            .then((res) => {
                if (res && !res.isFailed && res.result) {
                    const userProfile = res?.result?.user_profile
                    dispatch(setIdentity(userProfile));
                }
            })
            .catch(error => { enqueueSnackbar("Can not get user information", { variant: "error" }) })
    }, []);

    useEffect(() => {
        gethUserInfo();
    }, [gethUserInfo])

    const handleClickLogin = () => {
        dispatch(showSignInUpDialog())
    }

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'isLoggedOut') {
                handleSignOut();
            }
            if (event.key === 'isLoggedIn') {
                window.location.reload();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [])

    const handleSignOut = () => {
        setIsShow(false);
        dispatch(setIdentity(undefined))
        dispatch(clearIdentity())
        dispatch(clearToken())
        dispatch(clearRefreshToken())
        clearHistory()
        // deleteShopifyAccessToken()
        navigate(appConfig.paths.root)
    }

    const handleClickAvatar = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setIsShow(!isShow)
    }

    const handleAdminClick = () => {
        navigate(appConfig.paths.adminPage);
    }

    const saveListLink = useMemo(() => {
        return `${appConfig.paths.user}/${credential?.user_id}/${userPagePath.saveList}`;
    }, [credential?.user_id])

    return (
        <>
            <Box sx={{
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                },
            }}>
                {credential ?
                    <>
                        {isMobile ?
                            <Avatar alt='user-avatar' src={credential.user_avatar || DefaultAvatar} sx={{ width: '32px', height: '32px', marginRight: '5px', cursor: 'pointer' }}
                                onClick={handleClickAvatar} />

                            :
                            <Box sx={{ border: '1px solid #EEEEEE', borderRadius: '72px', width: '76px', height: '40px', display: 'flex', alignItems: 'center', cursor: "pointer" }}
                                onClick={handleClickAvatar} >
                                <SvgComponent iconName='ico-menu' style={{ width: '14px', height: '12px', marginLeft: '15px', marginRight: '11px', cursor: 'pointer' }} />
                                <Avatar alt='user-avatar' src={credential.user_avatar || DefaultAvatar} sx={{ width: '32px', height: '32px', marginRight: '5px', cursor: 'pointer' }} />

                            </Box>
                        }

                        <Box
                            sx={{
                                width: "280px",
                                background: "#FFFFFF",
                                border: "1px solid #EEEEEE",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                position: "absolute",
                                top: isMobile ? "50px" : "60px",
                                right: "10px",
                                zIndex: 2,
                            }}
                            style={{
                                display: isShow ? "block" : "none"
                            }}
                        >
                            <Box sx={{ height: "168px", background: "#ECF5FB", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                <Avatar src={credential?.user_avatar} style={{ width: "72px", height: "72px" }} />
                                <span style={{
                                    marginTop: "12px",
                                    fontWeight: 800,
                                    fontSize: '16px',
                                    color: '#000000',
                                    width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center',
                                }}>{credential?.user_name || credential?.user_id || ''}</span>
                            </Box>
                            <Box>
                                {credential.is_admin &&
                                    <>
                                        <MenuItem
                                            onClick={handleAdminClick}
                                            sx={{
                                                width: "100%",
                                                height: "56px",
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: 'pointer',
                                                paddingLeft: '20px'
                                            }}
                                        >
                                            <img src={ico_admin} alt="ico_admin" style={{
                                                width: "18px",
                                                height: "20px",
                                                marginRight: "12px"
                                            }} />
                                            <span style={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                fontFamily: 'Helvetica Neue',
                                                color: 'black'
                                            }}>{t("app.admin.administration")}</span>
                                        </MenuItem>
                                        <Divider sx={{ margin: '0 20px !important' }} />

                                    </>
                                }
                                <MenuItem
                                    component={Link}
                                    to={`${appConfig.paths.user}/${credential?.user_id}`}
                                    sx={{
                                        width: "100%",
                                        height: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: 'pointer',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    <img src={ico_profile} alt="ico_profile" style={{
                                        width: "18px",
                                        height: "20px",
                                        marginRight: "12px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black'
                                    }}>{t("app.login.my_profile")}</span>
                                </MenuItem>
                                <Divider sx={{ margin: '0 20px !important' }} />
                                <MenuItem
                                    component={Link}
                                    to={`${appConfig.paths.member}`}
                                    sx={{
                                        width: "100%",
                                        height: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: 'pointer',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    {/* !todo记得换一下黑图标 */}
                                    <img src={IcoMember} alt="ico_member" style={{
                                        width: "18px",
                                        height: "20px",
                                        marginRight: "12px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black'
                                    }}>{t("app.member.member_subscription")}</span>
                                </MenuItem>
                                <Divider sx={{ margin: '0 20px !important' }} />
                                <MenuItem
                                    component={Link}
                                    to={saveListLink}
                                    sx={{
                                        width: "100%",
                                        height: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: 'pointer',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    <img src={ico_save} alt="ico_save" style={{
                                        width: "14px",
                                        marginRight: "12px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black'
                                    }}>{t("app.login.save_list")}</span>
                                </MenuItem>
                                <Divider sx={{ margin: '0 20px !important' }} />
                                <MenuItem
                                    component={Link}
                                    to="/settings"
                                    sx={{
                                        width: "100%",
                                        height: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: 'pointer',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    <img src={ico_settings} alt="ico_settings" style={{
                                        width: "18px",
                                        height: "18px",
                                        marginRight: "12px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black',
                                    }}>{t("app.login.settings")}</span>
                                </MenuItem>
                                {/* <Divider sx={{ margin: '0 20px !important' }} /> */}
                                <Divider sx={{ margin: '0 20px !important' }} />
                                <MenuItem
                                    component={Link}
                                    to={appConfig.paths.wallet}
                                    sx={{
                                        width: "100%",
                                        height: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: 'pointer',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    <img src={ico_wallet} alt="ico_wallet" style={{
                                        width: "18px",
                                        height: "16px",
                                        marginRight: "14px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black'
                                    }}>{t("app.login.wallet")}</span>
                                </MenuItem>
                                <Divider sx={{ margin: '0 20px !important' }} />
                                <MenuItem sx={{ width: "100%", height: "56px", display: "flex", alignItems: "center", cursor: 'pointer', paddingLeft: '20px' }}
                                    onClick={() => {
                                        handleSignOut()
                                        localStorage.setItem('isLoggedOut', String(Date.now()));
                                    }}>
                                    <img src={ico_logout} alt="ico_logout" style={{
                                        width: "18px",
                                        height: "20px",
                                        marginRight: "12px"
                                    }} />
                                    <span style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica Neue',
                                        color: 'black'
                                    }}>{t("app.login.login_out")}</span>
                                </MenuItem>
                            </Box>

                        </Box>
                    </>
                    :
                    <>
                        {
                            !isMobile && <Button
                                sx={{
                                    background: '#3A92F9',
                                    borderRadius: '72px',
                                    width: '76px',
                                    height: '40px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    fontFamily: 'Helvetica Neue',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    color: 'white',
                                    textTransform: 'none',
                                    ':hover': {
                                        background: '#3A92F9AA',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        borderRadius: '64px',
                                        width: '56px',
                                        height: '32px',
                                    }
                                }} onClick={handleClickLogin}>
                                {t("app.login.login")}
                            </Button>
                        }
                    </>
                }
            </Box>

            {showSignInDialog &&
                <SignInUpDialog
                    onSignInSuccess={() => {
                        navigate(appConfig.paths.root);
                        // enqueueSnackbar("Login success", { variant: "success" });
                    }}
                />
            }
            
            {
                showFeedbackDialog && 
                <FeedbackDialog/>
            }

            {
                showFeedbackAlert && 
                <FeedbackTipDialog />
            }

        </>
    )
}

export default UserInfo;