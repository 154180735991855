import { Avatar, Badge, Box, CircularProgress, Divider, ListItem, ListItemAvatar, Tab, Tabs, useTheme } from "@mui/material";
import { useGetNotificationMessageListMutation, useDeleteUserDialogMutation } from '../../../service/api/notification-api';
import { FC, useCallback, useEffect, useState } from "react";
import { FollowerNotification, LastNotificationTime, MessageContentType, MessageNotification, NotificationTypeEnum, PostNotification, RequestNotification } from "../../../models/notification";
import { showSignInUpDialog } from "src/service/redux/signinup-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../tools/toolkit/store";
import { useTranslation } from "react-i18next";
import IcoDelete from "src/images/header/ico-delete.svg";
import { howLongAgo, isJSON } from "src/tools/utils/stringUtil";
import SvgComponent from "../../svgComponent";
import ChatDialog from "src/components/chat/ChatDialog";
import { enqueueSnackbar } from "notistack";
import { MessageTypeEnum, ChatTypeEnum, NotificationTypeTypeEnum } from "src/models/post";
import {
  useGetRelevantMessageMutation,
} from "src/service/api/chatroom-api";
import { useNavigate } from "react-router-dom";
import { appConfig } from "src/app-config";
import { useSetDialogMessageReadMutation } from "src/service/api/chatroom-api";

interface Props {
  hasNewNotification: () => void,
  handleReadNotification: (id: string) => void
  handleIsLoading: (isLoading: boolean) => void;
}

const MessagesTab = ({
  hasNewNotification,
  handleReadNotification,
  handleIsLoading
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { credential } = useSelector(state => state.app);
  const [messageList, setMessageList] = useState<MessageNotification[]>([])
  const [showChatDialog, setShowChatDialog] = useState(false);
  const [talkingUser, setTalkingUser] = useState<MessageNotification>();
  const { t, i18n } = useTranslation();

  const [getNotificationMessageList, { isLoading: messageListLoading }] = useGetNotificationMessageListMutation();
  const [getRelevantMessage, { isLoading: getLoading }] = useGetRelevantMessageMutation();
  const [deleteUserDialog] = useDeleteUserDialogMutation();
  const [setDialogMessageRead] = useSetDialogMessageReadMutation();

  const handleDialogSetMessageRead = (dialogId: string) => setDialogMessageRead({ id: dialogId });

  useEffect(() => {
    handleIsLoading(getLoading);
  }, [getLoading, handleIsLoading])

  const handleGetRelevantMessage = (item, notificationId: string) => {
    const param = {
      room_id: item?.room_info?.room_id,
      message_id: item?.last_message?.room_message_id,
      page_size: 30,
      type: "context",
    };
    getRelevantMessage(param)
      .unwrap()
      .then((res) => {
        if (res?.resultCode === 201634) {
          handleDialogSetMessageRead(item?.dialog_id);
          enqueueSnackbar(t("app.notification.no_message_tip"), { variant: "error" });
          return;
        }
        const result = res?.result;
        if (!res?.isFailed) {
          if (result?.itemList) {
            const extendedResult = {
              ...result,
              searchValue: "",
              messageId: item?.last_message?.room_message_id,
              dialogId: item?.dialog_id
            };
            handleReadNotification(notificationId);
            navigate(`${appConfig.paths.chatroom}?id=${item?.room_info?.room_id}`,
              {
                state: { notificationMessage: extendedResult }
              });
          } else {
            enqueueSnackbar(t("app.notification.no_message_tip"), { variant: "error" });
          }
        } else {
          enqueueSnackbar(t("app.notification.no_message_tip"), { variant: "error" });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleClickSendMessage = (user, notificationId: string) => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    }
    if (user?.dialog_type === ChatTypeEnum.oneToOne) {
      setShowChatDialog(true);
      setTalkingUser(user);
    } else if (user?.dialog_type === ChatTypeEnum.chatroom) {
      handleGetRelevantMessage(user, notificationId);
    }
  };


  const getShownContent = (notification: MessageNotification) => {
    const content = notification.last_message.content;
    if (notification.last_message.type === MessageContentType.Text) {
      return content;
    } else if (notification.last_message.type === MessageContentType.Image) {
      return t("app.chat.image");
    } else if (notification.last_message.type === MessageContentType.Video) {
      return t("app.chat.video");
    } else if (notification.last_message.type === MessageContentType.Audio) {
      return t("app.chat.audio");
    } else if (notification.last_message.type === MessageContentType.Post) {
      return t("app.chat.post");
    } else if (notification.last_message.type === MessageContentType.SharePost) {
      return t("app.chat.share_post");
    } else if (notification.last_message.type === MessageContentType.ShareLocation) {
      return t("app.chat.share_location");
    } else if (notification.last_message.type === MessageContentType.ShareRequest) {
      return t("app.chat.share_request");
    } else if (notification.last_message.type === MessageContentType.ShareUser) {
      return t("app.chat.share_user");
    } else if (notification.last_message.type === MessageContentType.ShareTopic) {
      return t("app.chat.share_topic");
    } else if (notification.last_message.type === MessageContentType.ShareRegion) {
      return t("app.chat.share_region");
    } else {
      if (isJSON(content)) {
        return t("app.chat.link");
      } else {
        return content;
      }
    }
  }

  const deleteUserDialogFun = async (dialogId) => {
    const res = await deleteUserDialog({ dialogId }).unwrap();
  }

  const handleDeleteUserDialog = async (e, item) => {
    e.stopPropagation();
    await deleteUserDialogFun(item.dialog_id);
    handleLoadMessageList();
  }

  const handleLoadMessageList = useCallback(() => {
    getNotificationMessageList({ userId: credential?.user_id || '' }).unwrap().then(res => {
      if (res && !res.isFailed && res.result) {
        const dataList = res.result.dialogs;
        setMessageList(dataList);
      }
      else {
        enqueueSnackbar('Failed to load requests list')
      }
    })
  }, [credential?.user_id, getNotificationMessageList])

  useEffect(() => {
    handleLoadMessageList();
  }, [handleLoadMessageList])

  const chatroomMessageBackground = (item) => {
    if (item?.dialog_type === ChatTypeEnum.chatroom) {
      if (item?.last_message?.notification_type === NotificationTypeTypeEnum.mention) {
        return "#F9FDFF"
      }
    }
    return ""
  }

  const messageAvatar = (item) => {
    if (item?.dialog_type === ChatTypeEnum.oneToOne) {
      return item.talk_with.user_avatar;
    } else if (item?.dialog_type === ChatTypeEnum.chatroom) {
      return item.room_info.room_cover;
    } else {
      return item.talk_with.user_avatar;
    }
  }

  const messageTitle = (item) => {
    if (item?.dialog_type === ChatTypeEnum.oneToOne) {
      return item.talk_with.user_name || item.talk_with.user_id;
    } else if (item?.dialog_type === ChatTypeEnum.chatroom) {
      return item.room_info.city || item.room_info.province || item.room_info.nation;
    } else {
      return "---";
    }
  }

  const messageContent = (item) => {
    if (item?.dialog_type === ChatTypeEnum.oneToOne) {
      return getShownContent(item);
    } else if (item?.dialog_type === ChatTypeEnum.chatroom) {
      if (item?.last_message?.notification_type === NotificationTypeTypeEnum.mention) {
        return t("app.chat.mentioned", { user: item?.last_message_sender?.user_name || item?.last_message_sender?.user_id })
      } else if (item?.last_message?.notification_type === NotificationTypeTypeEnum.reply) {
        return t("app.chat.replied", { user: item?.last_message_sender?.user_name || item?.last_message_sender?.user_id })
      }
    } else {
      return "---";
    }
  }

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper', padding: "10px 10px", whiteSpace: 'no-wrap', maxHeight: "525px", overflow: "auto", position: "relative" }}>

        {messageListLoading ?
          <ListItem sx={{ justifyContent: "center" }}>
            <CircularProgress sx={{ marginTop: '20px' }} />
          </ListItem>
          :
          <>
            {messageList.length > 0 ?
              <>
                {messageList.map((item) => (
                  <ListItem button key={item.dialog_id} alignItems="flex-start"
                    sx={{
                      textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'no-wrap',
                      cursor: "pointer",
                      background: chatroomMessageBackground(item),
                      "&:hover": {
                        backgroundColor: 'rgba(249, 253, 255, 1)'
                      },
                      "&:hover .deleteIcon": {
                        display: "inline-block",
                      },
                    }}
                    onClick={() => handleClickSendMessage(item, String(item?.dialog_id))}>
                    <ListItemAvatar sx={{ position: "relative" }}>
                      <Avatar alt="avatar" src={messageAvatar(item)} />
                      <Badge color="error" badgeContent={item.unread_count} sx={{ position: 'absolute', top: "6px", right: "20px" }} />
                    </ListItemAvatar>
                    <Box sx={{
                      display: "flex", flexDirection: "column", justifyContent: "space-around", width: "100%", height: "100%", flexGrow: 1,
                      textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'no-wrap', marginTop: "12px"
                    }}>
                      <Box sx={{
                        display: "flex", justifyContent: "space-between",
                        textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'no-wrap',
                      }}>
                        <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, color: "#000000", lineHeight: '16px' }}>{messageTitle(item)}</Box>
                        <Box sx={{ fontFamily: "SF Pro Text", fontSize: "12px", fontWeight: 400, color: "#999999" }}>{howLongAgo(item.last_message_time, t)}</Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '6px'
                        }}
                      >
                        <Box sx={{
                          fontFamily: "SF Pro Text",
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "#666666",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: '14px',
                          lineClamp: 2,
                          WebkitLineClamp: 2,
                          display: '-webkit-box',
                          '-webkit-box-orient': 'vertical'
                        }}>
                          {messageContent(item)}
                        </Box>
                        <Box
                          component="img"
                          className="deleteIcon"
                          sx={{ display: 'none', zIndex: 0 }}
                          src={IcoDelete}
                          onClick={(e) => handleDeleteUserDialog(e, item)}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </>
              :
              <ListItem sx={{ justifyContent: "center", height: '230px' }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <SvgComponent iconName={'ico-no-results'} style={{ width: '48px', height: '49px' }} />
                  <Box sx={{ fontFamily: "SF Pro Text", fontSize: "14px", fontWeight: 500, lineHeight: "16.71px", color: "#717171", marginTop: "10px" }}>{t("app.alert.no-data")}</Box>
                </Box>
              </ListItem>
            }
          </>
        }
      </Box>
      {showChatDialog && talkingUser &&
        <ChatDialog
          open={showChatDialog}
          onClickClose={() => {
            setShowChatDialog(false);
            handleLoadMessageList();
            hasNewNotification();
          }}
          chatUserId={talkingUser.talk_with.user_id || ''}
          chatUserName={talkingUser.talk_with.user_name || talkingUser.talk_with.user_id || ''}
          chatType={talkingUser?.talk_with?.type === "AI" ? "ai" : "user"}
        />
      }
    </>
  )
}

export default MessagesTab;