import { type RouteObject } from "react-router-dom";
import { paths } from 'src/app-config';
import DefaultLayout from "../DefaultLayout";
import Loadable from "src/components/loadable/Loadable";
import ProtectedRoute from "./ProtectedRoute";

const MapPage = Loadable(async () => await import("src/pages/map/MapPage"));
const MarketPage = Loadable(async () => await import("src/pages/main/market"));
const MainTabLayout = Loadable(async () => await import("src/pages/main/TabWrapper"));
const MainPage = Loadable(async () => await import("src/pages/main/home/HomePage"));
const FollowingPage = Loadable(async () => await import("src/pages/main/following/Following"));
const Worldview = Loadable(async () => await import("src/pages/main/worldview/WorldviewIndex"));
const WebPostDetail = Loadable(async () => await import("src/pages/details-web/post/WebPostDetail"));
const RequestDetail = Loadable(async () => await import("src/pages/request/RequestDetail"));
const LocationPage = Loadable(async () => await import("src/pages/location"));
const LocationAreaPage = Loadable(async () => await import("src/pages/location-area/LocationArea"));
const MemberPage = Loadable(async () => await import("src/pages/member/Member"));
const TopicPage = Loadable(async () => await import("src/pages/topic/TopicPage"));
const AutoTopicPage = Loadable(async () => await import("src/pages/topic-auto/AutoTopicPage"));

const RecommendLocations = Loadable(async () => await import("src/pages/recommend/locations"));
const MostVisitedLocations = Loadable(async () => await import("src/pages/recommend/mostVisitedLocations"));
const RecommendPeople = Loadable(async () => await import("src/pages/recommend/people"));
const SendRequest = Loadable(async () => await import("src/pages/request/SendRequest"));
const PaymentSuccess = Loadable(async () => await import("src/pages/payment/PaymentSuccess"));
const SupportPage = Loadable(async () => await import("src/pages/support/SupportPage"));
const DeleteAccount = Loadable(async () => await import("src/pages/support/DeleteAccount"));
const SettingsPage = Loadable(async () => await import("src/pages/settings/SettingsPage"));
const DeleteAccountFromSettings = Loadable(async () => await import("src/pages/settings/DeleteAccountFromSettings"));
const FeedbackFromSetting = Loadable(async () => await import("src/pages/settings/FeedbackFromSetting"));
const SearchPage = Loadable(async () => await import("src/pages/search"));
const Wallet = Loadable(async () => await import("src/pages/wallet/Wallet"));
const Recharge = Loadable(async () => await import("src/pages/recharge"));
const AdminPage = Loadable(async () => await import("src/pages/admin/index"));
const NewsDetail = Loadable(async () => await import("src/pages/news/NewsDetail"));
const RequestPurchasePost = Loadable(async () => await import("src/pages/post-purchase/RequestPurchasePost"));
const InterestQuestionsPage = Loadable(async () => await import("src/pages/settings/interest-questions/InterestQuestionsPage"));
const CollectionPage = Loadable(async () => await import("src/pages/collection/CollectionPage"));
const Recommend = Loadable(async () => await import("src/pages/recommendPage/Recommend"));
const ChatroomListPage = Loadable(async () => await import("src/pages/chatroom/ChatroomList"));
const ChatroomPage = Loadable(async () => await import("src/pages/chatroom/Chatroom"));
const TravelPage = Loadable(async () => await import("src/pages/travel/index"));

const UserPage = Loadable(async () => await import("src/pages/user/User"));
const AIUserPage = Loadable(async () => await import("src/pages/user/aiProfile/AIProfile"));
const UserRedirectPage = Loadable(async () => await import("src/pages/user/UserRedirect"));
const AIPostListPage = Loadable(async () => await import("src/pages/chatroom/AIPostList"));
const LoginPage = Loadable(async () => await import("src/components/dialog/signIn/SignInUpDialog"));


const DefaultLayoutRoutes: RouteObject = {
  path: '/',
  element: <DefaultLayout />,
  children: [
    {
      path: '/',
      element: <MainTabLayout />,
      children: [{
        path: paths.root,
        element: <MainPage />
      },
      {
        path: paths.markers,
        element: <MarketPage />
      },
      {
        path: paths.following,
        element: <FollowingPage />
      },
      {
        path: `${paths.worldview}/*`,
        element: <Worldview />
      },
      {
        path: paths.chatroomList,
        element: <ChatroomListPage />
      },
      {
        path: paths.chatroom,
        element: <ChatroomPage />
      },
      {
        path: `${paths.travel}/*`,
        element: <TravelPage />
      }
      ]
    },
    {
      path: paths.webPostDetail,
      element: <WebPostDetail />
    },
    {
      path: paths.requestDetail,
      element: <RequestDetail />
    },
    {
      path: paths.location,
      element: <LocationPage />
    },
    {
      path: paths.locationArea,
      element: <LocationAreaPage />
    },
    {
      path: `/${paths.topic}/:topic`,
      element: <TopicPage />
    },
    {
      path: `/${paths.autoTopic}/:topic`,
      element: <AutoTopicPage />
    },
    {
      path: paths.member,
      element: <MemberPage />
    },
    {
      path: '/recommend',
      element: <Recommend />
    },
    {
      path: paths.user,
      element: <UserRedirectPage />
    },
    {
      path: `/${paths.user}/:userId/*`,
      element: <UserPage />
    },
    {
      path: `/${paths.aiProfile}/:userId/*`,
      element: <AIUserPage />
    },
    // {
    //   path: `${paths.user}/:followType/`,
    //   element: <UserMainPage />
    // },
    // {
    //   path: paths.sharedUser,
    //   element: <UserMainPage />,
    // },
    {
      path: paths.recommendedLocations,
      element: <RecommendLocations />
    },
    {
      path: paths.mostVisitedLocations,
      element: <MostVisitedLocations />
    },
    {
      path: paths.recommendedPeople,
      element: <RecommendPeople />
    },
    {
      path: paths.sendRequest,
      element: <SendRequest />
    },
    {
      path: paths.paymentSuccess,
      element: <PaymentSuccess />
    },
    {
      path: paths.support,
      element: <SupportPage />
    },
    {
      path: paths.deleteAccount,
      element: <DeleteAccount />
    },
    {
      path: paths.settings,
      element: <SettingsPage />
    },
    {
      path: paths.interestQuestions,
      element: <InterestQuestionsPage />
    },
    {
      path: paths.deleteAccountFromSettings,
      element: <DeleteAccountFromSettings />
    },
    {
      path: paths.feedbackFromSettings,
      element: <FeedbackFromSetting />
    },
    {
      path: paths.search,
      element: <SearchPage />
    },
    {
      path: paths.wallet,
      element: (<ProtectedRoute><Wallet /></ProtectedRoute>)
    },
    {
      path: paths.recharge,
      element: <ProtectedRoute><Recharge /></ProtectedRoute>
    },
    {
      path: `${paths.adminPage}/*`,
      element: <AdminPage />
    },
    {
      path: paths.newsDetail,
      element: <NewsDetail />
    },
    {
      path: paths.map,
      element: <MapPage />
    },
    {
      path: paths.requestPurchasePost,
      element: <RequestPurchasePost />
    },
    {
      path: paths.collection,
      element: <CollectionPage />
    },
    {
      path: paths.aiPostList,
      element: <AIPostListPage />
    },
    {
      path: paths.login,
      element: <LoginPage />
    },
  ]
}


export default DefaultLayoutRoutes;